import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Pane from '../views/Pane.vue'
import PaneWarp from '../views/PaneWarp.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{
      auth:true,
    }
  },
  {
    path: '/Pane',
    name: 'Pane',
    component: Pane,
    meta:{
      auth:true,
    },
    children: [
      {
        path: 'actived_code',
        name: 'actived_code',
        component: () => import('@/views/ActivedCode'),
        meta: {title: '分发激活码', auth: true}
      },
      {
        path: 'chat',
        name: 'chat',
        component: () => import('@/views/Chat'),
        meta: {title: '聊天话术', auth: true}
      },
      {
        path: 'user_devices',
        name: 'user_devices',
        component: () => import('@/views/UserDevices'),
        meta: {title: '用户设备', auth: true}
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/views/Search'),
        meta: {title: '查询', auth: true}
      },
      {
        path: 'user_center',
        name: 'user_center',
        component: () => import('@/views/user-center/index'),
        meta: {title: '个人信息', auth: true}
      },
      {
        path: 'user_center_pay_rec_log',
        name: 'user_center_pay_rec_log',
        component: () => import('@/views/user-center/pay_rec_log'),
        meta: {title: '充值消费记录', auth: true}
      },
      {
        path: 'user_center_replace_code_rec_log',
        name: 'user_center_replace_code_rec_log',
        component: () => import('@/views/user-center/replace_code_rec_log'),
        meta: {title: '换卡记录', auth: true}
      },
      {
        path: 'replace_code_log_detail',
        name: 'replace_code_log_detail',
        component: () => import('@/views/user-center/replace_code_log_detail'),
        meta: {title: '换卡记录详细', auth: true}
      },
    ]
  },
  {
    path: '/',
    name: 'Login',
    meta:{
      auth:false,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/replace_key',
    name: 'replace_key',
    component: PaneWarp,
    meta:{
      auth:true,
      title:"换卡"
    },
    children: [
      {
        path: 'index',
        name: 'replace_key_index',
        component: () => import('../views/replaceKey/index.vue'),
        meta: {title: '换卡', auth: true}
      },
      {
        path: 'code_info',
        name: 'replace_code_info',
        component: () => import('../views/replaceKey/codeinfo.vue'),
        meta: {title: '换卡信息', auth: true}
      },
      {
        path: 'replace_success',
        name: 'replace_success',
        component: () => import('../views/replaceKey/replace_success.vue'),
        meta: {title: '换卡成功', auth: true, leftClick:false}
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
