<template>
  <div class="home-con">

    <van-row class="head" type="flex" justify="center" align="center">
      <van-col span="6" style="padding-left:15px">蜗牛音效3.8.9</van-col>
      <van-col span="12"></van-col>
      <van-col span="6">
        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="userPopSelect"
          placement="left-start"
        >
          <template #reference>
            <van-icon name="user-o" size="20" style="margin-right:5px" />{{$store.state.user.username}}
          </template>
        </van-popover>

      </van-col>
    </van-row>
    <van-grid clickable :gutter="10" :column-num="3" >
      <van-grid-item icon="idcard" text="分发激活码" to="/Pane/actived_code" v-if="admin_level > 3"/>
      <van-grid-item icon="desktop-o" text="用户设备" to="/Pane/user_devices" v-if="admin_level > 3"/>
      <van-grid-item icon="chat-o" text="话术" to="/Pane/chat" v-if="admin_level > 3"/>
      <van-grid-item icon="search" text="查询" to="/Pane/search" v-if="admin_level > 0" />
      <van-grid-item icon="exchange" text="换卡" to="/replace_key/index" v-if="admin_level > 0" />
      <!-- <van-grid-item icon="idcard" text="激活码" /> -->
    </van-grid>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
  name: 'Home',
  components: {
  },
  data(){
    return {
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [
        { text: '退出登录', code:'exit' },
        { text: '个人中心', code:'user_center' }
      ],
    }
  },
  computed:{
    admin_level(){
      return this.$store.state.user.admin_level
    }
  },
  methods:{
    onClickLeft(){
      this.$toast('点左侧')
    },
    onClickRight(){
      this.$toast('点右侧')
    },
    userPopSelect(act){
      console.log(act)
      switch(act.code){
        case 'exit':
          Dialog.confirm({
            title: '确认退出登录？',
            message: '退出登录，清除登录信息',
          })
          .then(() => {
            this.$store.commit('logout')
            this.$router.push({name:'Login'})
            // on confirm
          })
          .catch(() => {
            // on cancel
          });
        case 'user_center':
          this.$router.push({name:'user_center'})
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home-con{

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
}
</style>