import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //是否登录 默认未登录
    isLogin: false,
    token: null,
    user:null,
  },
  mutations: {
    login(state,r){
      state.isLogin = true;
      state.token = r.msg;
      state.user = r.data
    },
    logout(state,data){
        sessionStorage.clear();
        Vue.set(state,'isLogin',false)
        Vue.set(state,'token',null)
        state = {
          isLogin: false,
          token: null,
          user:null
        };
    },
  },
  actions: {
  },
  getters:{
    getLoginState: (state) => {
      return state.isLogin && state.token!=null;
    },
  },
  modules: {
  }
})
