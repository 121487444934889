<template>
  <div class="pane-con">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'PaneWarp',
  methods:{
  }
}
</script>
<style lang="scss" scoped>
.pane-con{

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
}
</style>