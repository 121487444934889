import Vue from 'vue'
import App from './App.vue'

import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import store from './store'

Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  //返回一个路径的auth,如果路径中有一个auth为true,那么就为true
  if (to.matched.some(record => record.meta.auth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
      //检查登录状态
      const isLogin = store.getters.getLoginState
      if (!isLogin) {
        //没登录
        next({
          name: 'Login',
          // query: { redirect: to.fullPath }
        })
      } else {
        next()
      }

  //无需身份验证
  } else {
    next() // 确保一定要调用 next()
  }
})