<template>
  <div class="pane-con">
    <van-nav-bar
      :title="$route.meta.title"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  methods:{
    onClickLeft(){
      // this.$toast('点左侧')
      this.$router.go(-1)
    },
    onClickRight(){
      // this.$toast('点右侧')
    },
  }
}
</script>
<style lang="scss" scoped>
.pane-con{

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
  .head{
    background-color: #2587ee;
    height: 48px;
    color: #fff;
    margin-bottom:15px;
  }
}
</style>